//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from '@/api/v3'
import * as actionTypes from '@/store/actionTypes'
import ContactRowsMixin from './ContactRowsMixin'
import { mapActions } from 'vuex'
import { sectionsStore, teamsStore, groupsStore } from '@/store'
import { TdDivider, TdBtn } from 'td-ui'
import AddingContactsTable from './AddingContactsTable.vue'

export default {
  name: 'TabByPhone',
  components: {
    ContactRow: () => import('./ContactRow'),
    GroupsSelector: () => import('./GroupsSelector'),
    TdDivider,
    TdBtn,
    IconPlus: () => import('@tada/icons/dist/IconPlusM.vue'),
    AddingContactsTable,
    IconArrowLeft: () => import('@tada/icons/dist/IconArrowLeftM.vue'),
  },
  mixins: [ContactRowsMixin],
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      error: null,
      erroredGroups: null,
      importingContacts: [],
      selectedGroups: [],
      stage: 'adding',
      stopUploadingFlag: false,
    }
  },
  computed: {
    sections () {
      if (this.isSingleGroupTeam()) return null
      // TODO: change to return Object.values(sectionsStore.state.direct).sort(...
      return Object.keys(sectionsStore.state.direct).map(c => sectionsStore.getters.section(c)).sort((a, b) => {
        const { sortOrdering: orderA } = a
        const { sortOrdering: orderB } = b
        return +(orderA > orderB)
      })
    },
    commitButtonLabel () {
      const contactsCount = this.toAdd.length > 0 ? ` (${this.toAdd.length})` : ''
      return this.$t('modals.AddContact.inviteBtn') + contactsCount
    },
  },
  watch: {
    toAdd: function (newToAdd) {
      this.$emit('input', newToAdd)
    },
  },
  methods: {
    ...mapActions([
      actionTypes.UPDATE_CHAT,
    ]),
    isSingleGroupTeam (teamId) {
      return teamsStore.getters.isSingleGroupTeam(teamId)
    },
    handleStopUploading () {
      this.stopUploadingFlag = true
      this.stage = 'adding'
    },
    handleGroupSelection (selectedGroups) {
      this.selectedGroups = [...selectedGroups]
    },
    async commit () {
      this.error = null
      this.rows.forEach(row => {
        row._error = null
      })

      const teamId = teamsStore.state.currentTeamId

      const successlyAddedContacts = []

      this.stage = 'loading'

      for (const row of this.toAdd) {
        if (this.stopUploadingFlag) break
        if (row._success) continue

        const data = {
          phone: row.tel,
          given_name: row.name,
          family_name: row.surname,
          patronymic: row.patronymic,
          sections: row.section ? [row.section.uid] : [],
          status: row.status.id,
        }

        try {
          const contact = await api.contacts.add(teamId, data)
          this.$set(row, '_success', true)

          const { jid } = contact
          successlyAddedContacts.push({ jid, status: 'member' })
        } catch (e) {
          const { details } = e
          if (details) {
            this.$set(row, '_error', details)

            Object.keys(details).some(key => {
              const error = details[key]
              return (this.error = error)
            })
          } else {
            this.error = e.error
          }
          break
        }
      }

      this.stage = 'completed'

      if (!this.isSingleGroupTeam()) {
        await this.addContactsToGroups(successlyAddedContacts)
      }

      return successlyAddedContacts
    },
    async addContactsToGroups (contacts) {
      this.erroredGroups = null

      if (!contacts || contacts.length === 0) return

      if (!this.selectedGroups.length) return

      const groupsJidsToAdd = this.selectedGroups
      if (!groupsJidsToAdd || groupsJidsToAdd.length === 0) return

      for (const index in groupsJidsToAdd) {
        const jid = groupsJidsToAdd[index].jid

        try {
          await groupsStore.actions.loadMembers(jid)

          const oldGroup = groupsStore.state.data[jid]
          if (!oldGroup) continue

          contacts.map(async contact => groupsStore.actions.addMember({ groupId: jid, memberId: contact.jid }))
        } catch (e) {
          const { details } = e
          if (details) {
            Object.keys(details).some(key => {
              const error = details[key]
              return (this.error = error)
            })
          } else {
            console.error(e)
          }
          !this.error && (this.error = this.$t('modals.AddContact.stdErr'))

          if (!this.erroredGroups) this.erroredGroups = {}
          this.erroredGroups[jid] = true
        }
      }
    },
  },
}
