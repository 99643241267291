//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'AddingContactsTable',
  components: {
    IconError: () => import('@tada/icons/dist/IconXCircleS.vue'),
    ContactsImportProgress: () => import('./ContactsImportProgress.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    nothingFound: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      tableColumns: [
        {
          name: 'index',
          label: '№',
          field: 'index',
          align: 'left',
        },
        { name: 'displayName', label: 'Имя', field: 'displayName', align: 'left' },
        { name: 'phone', label: 'Телефон', field: 'phone', align: 'left' },
        { name: 'role', label: 'Должность', field: 'role', align: 'left' },
        { name: 'section', label: 'Отдел', field: 'section', align: 'left' },
        { name: 'success', label: '', field: '_success' },
      ],
    }
  },
  computed: {
    formattedData () {
      return this.value.map((item, index) => {
        const data = {
          index: index + 1,
          phone: item.tel || item.phone,
          given_name: item.name || item.given_name,
          family_name: item.surname || item.family_name,
          get displayName () { return this.family_name || this.given_name ? `${this.family_name} ${this.given_name}` : item.displayName },
          role: item.role,
          section: item.section,
          _success: item._success,
          _error: item._error,
        }

        return data
      })
    },
    successItemsCount () {
      return this.formattedData.filter(item => item._success).length
    },
  },
}
